const GamesContent = () => {
  return (
    <div>
      <div>
        <b>defaulting</b> to{" "}
        <a
          href="https://www.minecraft.net/en-us"
          target="_blank"
          rel="noreferrer"
        >
          Minecraft
        </a>
      </div>
      <div>
        <b>puzzling</b> in{" "}
        <a
          href="https://www.patricksparabox.com/"
          target="_blank"
          rel="noreferrer"
        >
          Patrick's Parabox
        </a>
      </div>
      <div>
        <b>platforming</b> through{" "}
        <a
          href="https://en.wikipedia.org/wiki/Super_Mario_World"
          target="_blank"
          rel="noreferrer"
        >
          Super Mario World
        </a>
      </div>
    </div>
  );
};

export default GamesContent;
