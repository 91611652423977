const ProjectsContent = () => {
  return (
    <div>
      <div>
        a <b>credit-card-sized PCB</b> that plays the game{" "}
        <a
          href="https://en.wikipedia.org/wiki/Simon_(game)"
          target="_blank"
          rel="noreferrer"
        >
          Simon
        </a>
      </div>
      <div>
        all sorts of <b>pixel art illustrations</b>
      </div>
      <div>
        various <b>mechanical keyboard & macropad</b> projects
      </div>
    </div>
  );
};

export default ProjectsContent;
