import "./NavItem.css";

const NavItem = (props) => {
  const onClick = () => {
    props.onItemClick(props.name);
  };

  return (
    <div className="NavItem" aria-selected={props.active} onClick={onClick}>
      {props.name}
    </div>
  );
};

export default NavItem;
