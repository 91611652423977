import "./SocialIcons.css";

import githubIcon from "./github.svg";
import linkedinIcon from "./linkedin.svg";
import discordIcon from "./discord.svg";

const GITHUB_PROFILE_URL = "https://github.com/jasonkatz";
const LINKEDIN_PROFILE_URL = "https://www.linkedin.com/in/jason-eliot-katz/";
const DISCORD_PROFILE_URL = "https://discordapp.com/users/251029738116612106";

const SocialIcons = () => {
  return (
    <div className="SocialIcons">
      <a href={GITHUB_PROFILE_URL} target="_blank" rel="noreferrer">
        <img
          className="SocialIcons-icon"
          src={githubIcon}
          alt="Jason Katz's GitHub profile"
        />
      </a>
      <a href={LINKEDIN_PROFILE_URL} target="_blank" rel="noreferrer">
        <img
          className="SocialIcons-icon"
          src={linkedinIcon}
          alt="Jason Katz's LinkedIn profile"
        />
      </a>
      <a href={DISCORD_PROFILE_URL} target="_blank" rel="noreferrer">
        <img
          className="SocialIcons-icon"
          src={discordIcon}
          alt="Jason Katz's Discord profile"
        />
      </a>
    </div>
  );
};

export default SocialIcons;
