import { useState } from "react";

import "./App.css";
import ContentWrapper from "./ContentWrapper";
import Nav from "./Nav";
import SocialIcons from "./SocialIcons";

const App = () => {
  const views = ["home", "games", "books", "projects"];
  const [currentView, setCurrentView] = useState("home");

  const onNavItemChange = (item) => {
    setCurrentView(item);
  };

  return (
    <div className="App">
      <div className="App-body">
        <header className="App-title color-white">jason katz</header>
        <main className="App-main color-white">
          <Nav items={views} active={currentView} onChange={onNavItemChange} />
          <ContentWrapper active={currentView} />
        </main>
        <footer>
          <SocialIcons />
        </footer>
      </div>
    </div>
  );
};

export default App;
