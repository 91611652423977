import "./ContentWrapper.css";
import HomeContent from "./HomeContent";
import GamesContent from "./GamesContent";
import BooksContent from "./BooksContent";
import ProjectsContent from "./ProjectsContent";

const ContentWrapper = (props) => {
  const contentComponents = {
    home: <HomeContent />,
    games: <GamesContent />,
    books: <BooksContent />,
    projects: <ProjectsContent />,
  };

  return (
    <div className="ContentWrapper">{contentComponents[props.active]}</div>
  );
};

export default ContentWrapper;
