import "./Nav.css";
import NavItem from "./NavItem";

const Nav = (props) => {
  const onNavItemClick = (item) => {
    if (item === props.active) {
      return;
    }

    props.onChange(item);
  };

  const items = props.items.map((item) => (
    <NavItem
      key={item}
      name={item}
      active={item === props.active}
      onItemClick={onNavItemClick}
    />
  ));

  return <nav className="Nav">{items}</nav>;
};

export default Nav;
