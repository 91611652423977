const BooksContent = () => {
  return (
    <div>
      <div>
        reading{" "}
        <a
          href="https://www.svpg.com/books/inspired-how-to-create-tech-products-customers-love-2nd-edition/"
          target="_blank"
          rel="noreferrer"
        >
          Inspired
        </a>
        , by Marty Cagan, for <b>work</b>
      </div>
      <div>
        reading{" "}
        <a
          href="https://en.wikipedia.org/wiki/The_Secret_Garden"
          target="_blank"
          rel="noreferrer"
        >
          The Secret Garden
        </a>
        , by Frances Hodgson Burnett, for <b>fun</b>
      </div>
      <div>
        reading{" "}
        <a
          href="https://www.saltfatacidheat.com/buy-book"
          target="_blank"
          rel="noreferrer"
        >
          Salt Fat Acid Heat
        </a>
        , by Samin Nosrat, for <b>life</b>
      </div>
    </div>
  );
};

export default BooksContent;
