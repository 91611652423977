const HomeContent = () => {
  return (
    <div>
      <div>
        nyc-based <b>software engineer</b>
      </div>
      <div>
        revolutionizing e-commerce & digital payments at{" "}
        <a href="https://www.nate.tech/" target="_blank" rel="noreferrer">
          nate
        </a>
      </div>
      <div>tech & video game fanboy</div>
    </div>
  );
};

export default HomeContent;
